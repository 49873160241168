import React from "react"
import { gsap, Back} from "gsap"
import { CustomEase } from 'gsap/CustomEase'
// import { getRandomInt } from '@utility/Utils'
import { useEventListener } from '@hooks/useEventListener'

gsap.registerPlugin(CustomEase);

function BigWave({state, ...props})
{
    const preClientWidth = React.useRef(0)
    const timer = React.useRef(0)
    const [resize, setResize] = React.useState(0)

    const containerRef = React.useRef()
    const svgRef = React.useRef()
    const waveRef = React.useRef()
    const gRef = React.useRef()
    const pointsRef = React.useRef([])
    const duration = 0.4
    const margin = 0
    // var sinus = CustomEase.create("sinus", "M0,0 C0.4,0 0.3,1 0.5,1 0.7,1 0.6,0 1,0");
    const sinus = Back.easeInOut.config(1.7)
    const amplitude = 16
    const frequency = 4
    const segments = 64

    const killall = () => {
        for (var i = 0; i < pointsRef.current.length; i++) {
            let p = pointsRef.current[i]
            p.kill && p.kill()
        }
        pointsRef.current = []
    }

    const pause = () => {
        for (var i = 0; i < pointsRef.current.length; i++) {
            let p = pointsRef.current[i]
            p.pause && p.pause()
        }
    }

    const play = () => {
        for (var i = 0; i < pointsRef.current.length; i++) {
            let p = pointsRef.current[i]
            p.play && p.play()
        }
    }

    React.useEffect(()=>{
        console.log(waveRef.current.points.length)
        preClientWidth.current = window.innerWidth

        pointsRef.current = [] 
        const { width, height} = containerRef.current.getBoundingClientRect()
        var svg = svgRef.current
        var wave = waveRef.current
        // wave.points.clear()

        var interval = (width - margin) / segments

        gsap.defaults({
            ease: "sine.inOut"
        })

        gsap.set(gRef.current, {
            y: 0
        })
        
        const baseY = height

        let renderd = false
        if( wave.points.length > 0){
            renderd = true
        }
        
        let p1 = svg.createSVGPoint()
        let p2 = svg.createSVGPoint()

        if( renderd ){
            p1 = wave.points[0]
            p2 = wave.points[1]
        }

        p1.x = width
        p1.y = baseY
        p2.x = 0
        p2.y = baseY

        if (!renderd) {
            wave.points.appendItem(p1)
            wave.points.appendItem(p2)
        }

        for (var i = 0; i <= segments; i++) {
            var norm = 1 - i / segments
            let point = wave.points[2+i]
            if( !renderd ){
                point = wave.points.appendItem(svg.createSVGPoint())
            }

            point.x = i * interval;
            point.y = (baseY - amplitude) / 2 * sinus(norm)

            let p = gsap.to(point,{
                duration: duration,
                y: baseY -point.y,
                repeat: -1,
                yoyo: true,
                // onUpdate: () => console.log('update')
            }).progress(norm * frequency)
            .pause()
            pointsRef.current.push(p)
        }
        return () => {
            killall()
        }
    }, [resize, sinus])

    React.useEffect(() => {
        console.log(state)
        if( state === 'play'){
            play()
        }
        if( state === 'pause'){
            pause()
        }
    },[state])
    
    
    const onResize = () => {
        console.log('resize')
        if(preClientWidth.current === window.innerWidth) return
        preClientWidth.current = window.innerWidth
        pause()
        setResize(resize + 1)
    }
    useEventListener('resize',()=>{
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            onResize()
        }, 300)
    })

    return (
        <div ref={containerRef} {...props}>
            <svg ref={svgRef}>
                <g ref={gRef}>
                    {/* <line id="line" x1="0" x2="100%" /> */}
                    <polyline ref={waveRef}
                        fill='#BBB'
                        strokeWidth={0}
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        stroke="none"
                    />
                </g>
            </svg>
        </div>
    )
}

export { BigWave }