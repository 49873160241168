import React from "react"
import { gsap } from "gsap"
import { CustomEase } from 'gsap/CustomEase'
// import { useEventListener } from '@hooks/useEventListener'
gsap.registerPlugin(CustomEase);

function Wave({state, ...props})
{
    const [render, setRender] = React.useState(false)
    const containerRef = React.useRef()
    const svgRef = React.useRef()
    const waveRef = React.useRef()
    const gRef = React.useRef()
    const pointsRef = React.useRef([])

    const margin = 32
    // var sinus = CustomEase.create("sinus", "M0,0 C0.4,0 0.3,1 0.5,1 0.7,1 0.6,0 1,0");
    const amplitude = 8
    const frequency = 2
    const segments = 16

    const killall = () => {
        for (var i = 0; i < pointsRef.current.length; i++) {
            let p = pointsRef.current[i]
            p.kill && p.kill()
        }
        pointsRef.current = []
        if (waveRef.current.points ){
            waveRef.current.points.clear()
        }
    }

    const pause = () => {
        for (var i = 0; i < pointsRef.current.length; i++) {
            let p = pointsRef.current[i]
            p.pause && p.pause()
        }
    }

    const play = () => {
        for (var i = 0; i < pointsRef.current.length; i++) {
            let p = pointsRef.current[i]
            p.play && p.play()
        }
    }

    React.useEffect(()=>{
        pointsRef.current = []
        const { width, height} = containerRef.current.getBoundingClientRect()
        var svg = svgRef.current
        var wave = waveRef.current
        wave.points.clear()

        var interval = (width - margin) / segments

        gsap.defaults({
            ease: "sine.inOut"
        });
        gsap.set(gRef.current, {
            y: height / 2
        })
        
        for (var i = 0; i <= segments; i++) {
            var norm = 1 - i / segments;
            var point = wave.points.appendItem(svg.createSVGPoint());

            point.x = margin/2 + i * interval;
            point.y = amplitude// / 2 * sinus(norm);

            let p = gsap.to(point,{
                duration: 1.0,
                y: -point.y,
                repeat: -1,
                yoyo: true,
                // onUpdate: () => console.log('update')
            }).progress(norm * frequency)
            .pause()
            pointsRef.current.push(p)
        }
        setRender(true)
        return () => {
            killall()
        }
    },[])

    React.useEffect(() => {
        console.log(state)
        if( state === 'play'){
            play()
        }
        if( state === 'pause'){
            pause()
        }
    },[state])


    return (
        <div ref={containerRef} {...props}>
            <svg ref={svgRef} id="wave">
                <g ref={gRef} y={50}>
                    { !render && <line
                        strokeWidth={16}
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        x1="10%" x2="90%"
                        y1="50%" y2="50%"
                        stroke="#fff"
                    /> }
                    <polyline ref={waveRef}
                        fill='none'
                        strokeWidth={16}
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        stroke="#fff"
                        points=""
                    />
                </g>
            </svg>
        </div>
    )
}

export { Wave }