import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { globalHistory } from '@reach/router'
import { motion, AnimatePresence } from 'framer-motion'
import { useEventListener } from '@hooks/useEventListener'
import useVh from "@hooks/useVh"

// import ThreeJs from '@components/Three/Main.js'
// import Cursor from '@components/Cursor'
import Header from '@layouts/Header'
import Footer from "@layouts/Footer"
import Loading from "@layouts/Loading"
// import ModalMenu from "@layouts/ModalMenu"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'

import '@css/style.styl'

export const TransitionContext = React.createContext({
    transitionState: 0,
    setTranstionState: ()=>{},
    duration: 0,
    delay: 0,
})

const options = {
    duration: 1.6,
    delay: 0.3,
}

export const useTransition = () => React.useContext(TransitionContext)

export function TransitionLayout({ children, location })
{
    // const [resize, setResize] = React.useState(0)
    const [transitionState, setTranstionState] = React.useState(3)
    const key = location.pathname
    const duration = 0
    const delay = 0
    useVh()
    console.log(location)

    const params = {
        initial: {
            position: 'relative',
            // opacity: 1,
        },
        enter: {
            transition: {
                duration: duration,
                delay: delay,
                when: 'beforeChildren',
            },
            // opacity: 1,
        },
        exit: {
            transition: { duration: duration },
            // opacity: 1,
        },
    }
    

    React.useEffect(() => {
        // 0: none, 1:start, 2:loading, 3: complete
        //start with TransitionLink.
        console.log(transitionState)
        //init
        if (transitionState === 1) {
            console.log('[[[ Transition <<< Show Start >>> ]]]')
        } else if (transitionState === 2) {
            console.log('[[[ Transition <<< Loading Start >>> ]]]')
        } else if (transitionState === 3) {
            console.log('[[[ Transition <<< Loading Complete >>> ]]]')
            setTranstionState(4)
        } else if (transitionState === 4) {
            console.log('[[[ Transition <<< Hide Start >>> ]]]')
            setTimeout(() => {
                setTranstionState(0)
            }, (options.duration + options.delay) * 1000)
        }
    }, [transitionState])

    //browser back push action
    useEventListener('popstate', () => {
        setTranstionState(0)
    })

    // useEventListener('resize', () => {
    //     setResize(resize + 1)
    // })

    return (
        <>
            {location.pathname.match('404.html') ?
                <div>{children}</div>
            :
                <TransitionContext.Provider value={{
                    transitionState: transitionState,
                    setTranstionState: setTranstionState,
                    duration: options.duration,
                    delay: options.delay,
                }}>
                    <div id="root-container" className="root-container">
                        <Loading />
                        <Header />
                        <AnimatePresence
                            onExitComplete={() => {
                                if (transitionState === 1) {
                                    setTranstionState(2)
                                }
                            }}
                            exitBeforeEnter
                        >
                            <motion.div
                                key={key}
                                variants={params}
                                initial="initial"
                                animate="enter"
                                exit="exit"
                                onAnimationStart={() => {
                                }}
                                onAnimationComplete={() => {
                                    if (transitionState === 2) {
                                        setTranstionState(3)
                                    }
                                }}
                            >
                                {children}
                                <Footer />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </TransitionContext.Provider>
            }
        </>
    )
}
