import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { TransitionLink } from "@atoms/Link"
// import { FluidImage } from "@atoms/Image"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'

import * as css from "@css/layouts/l-footer.module.styl"

export default function Footer()
{  
    return(
        <footer className={css.lFooter}>
            <div className={css.lFooter__inner}>
                <div className={css.lFooter__copyright}>
                    © {new Date().getFullYear()} re-paver.com
                </div>
            </div>
        </footer>
    )
}