
import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { TransitionLink } from "gatsby"
// import Image from 'gatsby-image'
import { gsap, Expo } from 'gsap'
import { useTransition } from '@layouts/TransitionLayout'
import { Wave } from '@atoms/Wave'
import { BigWave } from '@atoms/BigWave'

import * as css from "@css/layouts/l-loading.module.styl"

export default function Loading(props) {
    const { transitionState, duration, delay } = useTransition()
    // const q = useStaticQuery(graphql`
    //     query {
    //         logo : file(relativePath: { eq: "header__logo__daisen-white.svg" }) {
    //             publicURL
    //         }
    //     }
    // `)
    const containerRef = React.useRef()
    const titleRef = React.useRef()
    const loading = React.useRef(true)

    React.useEffect(() => {
        //start hide
        if (transitionState === 4) {
            if (!loading.current) return
            loading.current = false
            startHideContainer({ target: containerRef.current, duration, delay })
        } else
            if (transitionState === 1) {
                //start show
                if (loading.current) return
                loading.current = true
                startShowContainer({ target: containerRef.current, duration, delay })
            }

        if (transitionState === -1) {
            //browser back
            if (loading.current) return
            loading.current = true
            quiqShowContainer({ target: containerRef.current, duration, delay })
        }
    }, [transitionState, delay, duration])



    return (
        <div className={css.lLoading} ref={containerRef}>
            <BigWave className={`${transitionState === 1 ? css.lLoading__waveReverce : css.lLoading__wave}`}
                state={transitionState !== 0 ? 'play' : 'pause'} />
            <div className={css.lLoading__inner}>
                <div className={css.lLoading__loading} ref={titleRef}>
                    {/* <LogoSvg/> */}
                    <Wave className={css.lLoading__loading__icon}
                        state={transitionState !== 0 ? 'play' : 'pause'} />
                </div>
            </div>
        </div>
    )
}


function startHideContainer({ target, delay, duration }) {
    let tl = gsap.timeline()
    tl.set(target, {
        y: `0%`,
    })
        // .to(target, {
        //     duration: duration/5 * 1,
        //     marginTop: 100,
        // })
        .to(target, {
            ease: Expo.easeInOut,
            delay: delay,
            duration: duration / 5 * 5,
            // opacity: 0,
            // display: 'none',//svg waveアニメーションと相性がわるい
            y: `100%`,
            marginTop: 100,
            onComplete: () => {
                gsap.set(target, {
                    y: `-100%`,
                    marginTop: -100,
                })
            }
        })
}
function startShowContainer({ target, delay, duration }) {
    let tl = gsap.timeline()
    tl.set(target, {
        y: `-100%`,
        // marginTop: -100,
    })
    tl.to(target, {
        // ease: Back.easeInOut.config(1.7),
        ease: Expo.easeOut,
        display: 'flex',
        duration: duration / 5 * 2,
        marginTop: 0,
    })
        .to(target, {
            ease: Expo.easeInOut,
            duration: duration / 5 * 3,
            // opacity: 1,
            y: `0%`,
            marginTop: 0,
        })
}

function quiqShowContainer({ target, delay, duration }) {
    gsap.set(target, {
        y: `0%`,
        display: 'flex',
        marginTop: 0,
    })
    // gsap.to(target, {
    //     ease: Power4.easeInOut,
    //     delay: delay,
    //     duration: duration,
    //     // opacity: 1,
    //     display: 'none',
    //     y: `100%`,
    //     onComplete: () => {
    //         gsap.set(target, {
    //             y: `-100%`,
    //         })
    //     }
    // })
}
