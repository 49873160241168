import React from "react"
// import { graphql, useStaticQuery } from "gatsby"    
import { TransitionLink } from "@atoms/Link"
// import { globalHistory } from '@reach/router'
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
// import LinkList from '@projects/LinkList'
// import { menuData } from '@utility/UrlData'
// import { Svg } from '@atoms/Svg'

import * as css from "@css/layouts/l-header.module.styl"
import LogoSvg from "@images/Header__logo__repaver.svg"

export default function Header()
{
    // const { appStore } = useStore()
    // const { location } = globalHistory

    // const q = useStaticQuery(graphql`
    //     query {
    //         logo : file(relativePath: { eq: "Header__logo__repaver.svg" }) { publicURL }
    //     }
    // `)

    return(
        <header className={css.lHeader} id="header">
            <div className={css.lHeader__inner}>
                <TransitionLink className={css.aLogo} to="/">
                    <LogoSvg/>
                </TransitionLink>
            </div>
        </header>
    )
}

